import Screens from "../images/hosting/screens.svg"
import Server from "../images/hosting/server.svg"
import Settings from "../images/hosting/settings.svg"

export const ICON_TEXT_INTRODUCTION_HOSTING = [
  {
    href: "https://igloonet.hosting/webhosting/",
    heading: "Webhosting",
    text: [
      "Nejnovější PHP, přístup přes SSH, editace cronů a\u00A0spousta dalších vychytávek. Umíme taky Python, Ruby a\u00A0node.js",
    ],
    src: Screens,
    alt: "",
  },
  {
    href: "https://igloonet.hosting/virtualni-servery/",
    heading: "Virtuální servery",
    text: [
      "Potřebujete speciální nastavení či větší bezpečnost? Pořiďte si virtual v\u00A0našem privátním cloudu.",
    ],
    src: Server,
    alt: "",
  },
  {
    href: "https://igloonet.hosting/sprava-serveru/",
    heading: "Správa serverů",
    text: [
      "Na Linuxu umíme rozjet téměř vše. Hlavně ale umíme zařídit, aby to zůstalo běžet i\u00A0v\u00A0budoucnu.",
    ],
    src: Settings,
    alt: "",
  },
]
