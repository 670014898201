import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components"
import {
  Banner,
  BannerStyle,
  Button,
  ButtonStyle,
  IconTextIntroductionSection,
  PaddingWrapper,
} from "@igloonet-web/shared-ui"
import { ICON_TEXT_INTRODUCTION_HOSTING } from "../model/icon-text-introduction-hosting"

const Hosting: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Hosting – webhosting, virtuální servery, správa | igloonet</title>
      <meta
        name="description"
        content="Pomůžeme s výběrem domény a založením vhodného hostingu
            přesně podle vašich potřeb. Nabízíme klasický webhosting pro weby a
            eshopy i virtuální servery pro rozsáhlejší webové projekty."
      />
    </Helmet>
    <Banner
      style={BannerStyle.Hosting}
      orangeHeading="igloonet.hosting"
      blackHeading={`Webhosting,<br/>který roste s&nbsp;vámi`}
    />

    <PaddingWrapper>
      <IconTextIntroductionSection data={ICON_TEXT_INTRODUCTION_HOSTING} />
    </PaddingWrapper>

    <div className="pb-5 pt-3 mb-5 d-flex ">
      <div className="mx-auto">
        <Button to="//igloonet.hosting" style={ButtonStyle.Primary}>
          Více na igloonet.hosting
        </Button>
      </div>
    </div>
  </Layout>
)

export default Hosting
